import React, {Component} from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import {bindActionCreators} from 'redux';
import * as actions from '../../store/actions/common.actions';
import {connect} from 'react-redux';
import _ from 'lodash';
import utilService from '../../common/utilService'
import '../../styles/app/eten-color.scss'

const newConfirmState = {
    description  : "",
    title: "",
    dialog_type:"confirm" //"confirm", "info", "error"
};

class ConfirmDialog extends Component {

    state = {
        selectedConfirm:{...newConfirmState}
    };

    componentWillReceiveProps( props ){
    }

    componentDidUpdate(prevProps, prevState, snapshot)
    {
        /**
         * After Dialog Open
         */
        if ( !prevProps.confirmDialog.props.open && this.props.confirmDialog.props.open )
        {
            if (this.props.confirmDialog.data &&
                !_.isEqual(this.props.confirmDialog.data, prevState.selectedConfirm) )
            {
                let selectedConfirm = {...this.props.confirmDialog.data, dialog_type:this.props.confirmDialog.data.dialog_type||"confirm"};                
                this.setState({selectedConfirm:selectedConfirm});
            }
        }
    }

    closeComposeDialog = () => {
        this.props.closeConfirmDialog();
    };

    getClassName=() =>{
        const {selectedConfirm} = this.state;
        let ret = "";

        if(selectedConfirm.bg_name)
        {
            ret = `${selectedConfirm.bg_name} text-center`
        }
        else
        {
            if (selectedConfirm.dialog_type === "info") {
                ret = "bg-green text-center";
            } else {
                ret = "bg-red text-center";
            }
        }

        return ret;
    }

    getConfirmButtonName = () => {
        const {selectedConfirm} = this.state;
        if(selectedConfirm.confirm_btn)
        {
            return utilService.getLangByCode(`${selectedConfirm.confirm_btn}`)
        }

        return selectedConfirm.dialog_type==="confirm" ? utilService.getLangByCode("Yes") : utilService.getLangByCode("OK")
    }

    getCancelButtonName = () => {
        const {selectedConfirm} = this.state;
        if(selectedConfirm.cancel_btn)
        {
            return utilService.getLangByCode(`${selectedConfirm.cancel_btn}`)
        }

        return utilService.getLangByCode("No")
    }

    render()
    {
        const {confirmDialog, closeConfirmDialog} = this.props;
        const {selectedConfirm} = this.state;
        return (
            <Modal                    
                    isOpen={confirmDialog.props.open}
                    toggle={() => {
                        this.props.confirmCancel && this.props.confirmCancel(selectedConfirm); 
                        closeConfirmDialog()
                    }}
                    //style={{marginTop:'300px'}}
                    className="modal-dialog-centered w500 font-arial"
                >
                <ModalHeader toggle={() => {
                        this.props.confirmCancel && this.props.confirmCancel(selectedConfirm); 
                        closeConfirmDialog()
                    }} 
                    className={this.getClassName()}
                >
                    <div className="d-flex justify-content-center text-white" > 
                        <h3><b>{selectedConfirm.title||""}</b></h3>
                    </div>                        
                </ModalHeader>
                <ModalBody 
                    style={{paddingLeft:'100px', paddingRight:'100px', fontSize:'20px', textAlign:'center', lineHeight:'3rem'}}
                >
                    <span>{selectedConfirm.description||""}</span>
                </ModalBody>
                <ModalFooter className="d-flex justify-content-center mb-4" style={{border:0}}>
                    { selectedConfirm.dialog_type==="confirm" && 
                        <button 
                            className="btn btn-cancel"
                            data-test="close_btn"
                            onClick={() => {this.props.confirmCancel && this.props.confirmCancel(selectedConfirm); closeConfirmDialog()}}
                        >
                            {this.getCancelButtonName()}
                        </button>
                    }
                    <button
                        className="btn btn-ok"
                        data-test="confirm_btn"
                        onClick={() => {this.props.confirmDone(selectedConfirm); closeConfirmDialog()}}
                    >
                        { this.getConfirmButtonName() }
                    </button>
                </ModalFooter>
            </Modal>
        );
    }
}


function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        closeConfirmDialog: actions.closeConfirmDialog,
    }, dispatch);
}

function mapStateToProps({common})
{
    return {
        confirmDialog: common.confirmDialog
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(ConfirmDialog);
